import React, { useState } from 'react';
import Swiper from 'react-id-swiper';
import { Link } from 'gatsby';
import 'swiper/css/swiper.css';
// import bg1 from '../assets/images/slider/4.gif';
import bg2 from '../assets/images/slider/1.jpg';
import bg3 from '../assets/images/slider/12.jpeg';
import bg4 from '../assets/images/slider/3.jpg';
import bg5 from '../assets/images/slider/4.jpg';
import bg6 from '../assets/images/slider/5.jpg';
import bg7 from '../assets/images/slider/6.jpg';
import bg8 from '../assets/images/slider/7.jpg';
import bg9 from '../assets/images/slider/8.jpg';
import bg10 from '../assets/images/slider/9.jpg';
import bg11 from '../assets/images/slider/10.jpg';
import bg12 from '../assets/images/slider/11.jpg';
import bg13 from '../assets/images/slider/2.jpg';
// import schad from '../assets/images/slider/schoolad.png';

const SliderTwo = () => {
  const [swiper, setSwiper] = useState(null);

  const params = {
    spaceBetween: 30,
    loop: true,
    centeredSlides: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  };

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  return (
    <div className='banner-wrapper'>
      <section className='banner-two banner-carousel__one no-dots'>
        <Swiper getSwiper={setSwiper} {...params}>
          {/* <div
            className='banner-two__slide banner-two__slide-one'
            style={{
              backgroundImage: `url(${schad})`,
            }}
          >
            <div className='container'>
              <div className='row no-gutters'>
                <div className='col-xl-12' style={{ top: 254 }}>                  
                  <Link to='/schools-list'>
                    <h3 className='banner-two__title banner-two__light-color'>
                      Apply Now
                    </h3>
                  </Link>
                  
                </div>
              </div>
            </div>
          </div> */}
          {/* <div
            className='banner-two__slide banner-two__slide-one'
            style={{ backgroundImage: `url(${bg1})` }}
          >
            <div className='container' style={{ top: 420}}>
              <div className='row no-gutters'>
                <div className='col-xl-12' style={{ top: 254}}>
                  <p className='banner-two__tag-line'>LUTH</p>
                  <h3 className='banner-two__title banner-two__light-color'>
                    Lagos University Teaching Hospital
                  </h3>
                  <a href="#none" className="thm-btn banner-two__btn">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div> */}
          <div
            className='banner-two__slide banner-two__slide-two'
            style={{ backgroundImage: `url(${bg2})` }}
          >
            <div className='container'>
              <div className='row no-gutters'>
                <div className='col-xl-12' style={{ top: 254 }}>
                  {/* <p className='banner-two__tag-line'>NSIA-LUTH</p> */}
                  <h3 className='banner-two__title banner-two__light-color'>
                    Lagos University Teaching Hospital
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div
            className='banner-two__slide banner-two__slide-one'
            style={{ backgroundImage: `url(${bg3})` }}
          >
            <div className='container'>
              <div className='row no-gutters'>
                <div className='col-xl-12' style={{ top: 254 }}>
                  {/* <p className='banner-two__tag-line'>LUTH</p> */}
                  <h3 className='banner-two__title banner-two__light-color'>
                    Lagos University Teaching Hospital
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div
            className='banner-two__slide banner-two__slide-one'
            style={{ backgroundImage: `url(${bg4})` }}
          >
            <div className='container'>
              <div className='row no-gutters'>
                <div className='col-xl-12' style={{ top: 254 }}>
                  {/* <p className='banner-two__tag-line'>LUTH</p> */}
                  <h3 className='banner-two__title banner-two__light-color'>
                    Lagos University Teaching Hospital
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div
            className='banner-two__slide banner-two__slide-one'
            style={{ backgroundImage: `url(${bg5})` }}
          >
            <div className='container'>
              <div className='row no-gutters'>
                <div className='col-xl-12' style={{ top: 254 }}>
                  {/* <p className='banner-two__tag-line'>LUTH</p> */}
                  <h3 className='banner-two__title banner-two__light-color'>
                    Lagos University Teaching Hospital
                  </h3>
                  {/* <a href="#none" className="thm-btn banner-two__btn">
                    Learn More
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div
            className='banner-two__slide banner-two__slide-one'
            style={{ backgroundImage: `url(${bg6})` }}
          >
            <div className='container'>
              <div className='row no-gutters'>
                <div className='col-xl-12' style={{ top: 254 }}>
                  {/* <p className='banner-two__tag-line'>LUTH</p> */}
                  <h3 className='banner-two__title banner-two__light-color'>
                    Lagos University Teaching Hospital
                  </h3>
                  {/* <a href="#none" className="thm-btn banner-two__btn">
                    Learn More
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div
            className='banner-two__slide banner-two__slide-one'
            style={{ backgroundImage: `url(${bg7})` }}
          >
            <div className='container'>
              <div className='row no-gutters'>
                <div className='col-xl-12' style={{ top: 254 }}>
                  {/* <p className='banner-two__tag-line'>LUTH</p> */}
                  <h3 className='banner-two__title banner-two__light-color'>
                    Lagos University Teaching Hospital
                  </h3>
                  {/* <a href="#none" className="thm-btn banner-two__btn">
                    Learn More
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div
            className='banner-two__slide banner-two__slide-one'
            style={{ backgroundImage: `url(${bg8})` }}
          >
            <div className='container'>
              <div className='row no-gutters'>
                <div className='col-xl-12' style={{ top: 254 }}>
                  {/* <p className='banner-two__tag-line'>LUTH</p> */}
                  <h3 className='banner-two__title banner-two__light-color'>
                    Lagos University Teaching Hospital
                  </h3>
                  {/* <a href="#none" className="thm-btn banner-two__btn">
                    Learn More
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div
            className='banner-two__slide banner-two__slide-one'
            style={{ backgroundImage: `url(${bg9})` }}
          >
            <div className='container'>
              <div className='row no-gutters'>
                <div className='col-xl-12' style={{ top: 254 }}>
                  {/* <p className='banner-two__tag-line'>LUTH</p> */}
                  <h3 className='banner-two__title banner-two__light-color'>
                    Lagos University Teaching Hospital
                  </h3>
                  {/* <a href="#none" className="thm-btn banner-two__btn">
                    Learn More
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div
            className='banner-two__slide banner-two__slide-one'
            style={{ backgroundImage: `url(${bg10})` }}
          >
            <div className='container'>
              <div className='row no-gutters'>
                <div className='col-xl-12' style={{ top: 254 }}>
                  {/* <p className='banner-two__tag-line'>LUTH</p> */}
                  <h3 className='banner-two__title banner-two__light-color'>
                    Lagos University Teaching Hospital
                  </h3>
                  {/* <a href="#none" className="thm-btn banner-two__btn">
                    Learn More
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div
            className='banner-two__slide banner-two__slide-one'
            style={{ backgroundImage: `url(${bg11})` }}
          >
            <div className='container'>
              <div className='row no-gutters'>
                <div className='col-xl-12' style={{ top: 254 }}>
                  {/* <p className='banner-two__tag-line'>LUTH</p> */}
                  <h3 className='banner-two__title banner-two__light-color'>
                    Lagos University Teaching Hospital
                  </h3>
                  {/* <a href="#none" className="thm-btn banner-two__btn">
                    Learn More
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div
            className='banner-two__slide banner-two__slide-one'
            style={{ backgroundImage: `url(${bg12})` }}
          >
            
            <div className='container'>
              <div className='row no-gutters'>
                <div className='col-xl-12' style={{ top: 254 }}>
                  {/* <p className='banner-two__tag-line'>LUTH</p> */}
                  <h3 className='banner-two__title banner-two__light-color'>
                    Lagos University Teaching Hospital
                  </h3>
                  {/* <a href="#none" className="thm-btn banner-two__btn">
                    Learn More
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div
            className='banner-two__slide banner-two__slide-one'
            style={{ backgroundImage: `url(${bg13})` }}
          >
            
            <div className='container'>
              <div className='row no-gutters'>
                <div className='col-xl-12' style={{ top: 254 }}>
                  {/* <p className='banner-two__tag-line'>LUTH</p> */}
                  <h3 className='banner-two__title banner-two__light-color'>
                    Lagos University Teaching Hospital
                  </h3>
                  {/* <a href="#none" className="thm-btn banner-two__btn">
                    Learn More
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </Swiper>
      </section>
      <div className='banner-carousel-btn'>
        <div
          onClick={goPrev}
          onKeyDown={goPrev}
          role='button'
          tabIndex={0}
          className='banner-carousel-btn__left-btn banner-arrow'
        >
          <i className='kipso-icon-left-arrow'></i>
        </div>
        <div
          onClick={goNext}
          onKeyUp={goNext}
          role='button'
          tabIndex={0}
          className='banner-carousel-btn__right-btn banner-arrow'
        >
          <i className='kipso-icon-right-arrow'></i>
        </div>
      </div>
    </div>
  );
};

export default SliderTwo;
