import React, { useState } from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import slidestrech from '../assets/images/slider-1-scratch.png';
import person1 from '../assets/images/slider-1-person-1.png';
import person2 from '../assets/images/slider-1-person-2.png';
import doc from '../assets/images/pexels1.png';
import guy from '../assets/images/g-medic-removebg.png';
import ola from '../assets/images/pexels2.png';
const SliderOne = () => {
  const [swiper, setSwiper] = useState(null);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };
  const params = {
    spaceBetween: 30,
    loop: true,
    centeredSlides: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  return (
    <div className='banner-wrapper'>
      <section className='banner-one banner-carousel__one no-dots'>
        <Swiper getSwiper={setSwiper} {...params}>          
          <div className='banner-one__slide banner-one__slide-two'>
            <div className='container'>
              {/* <div className="banner-one__bubble-1"></div>
              <div className="banner-one__bubble-2"></div>
              <div className="banner-one__bubble-3"></div> */}
              {/* <img src={slidestrech} alt="" className="banner-one__scratch" /> */}
              {/* <img src={doc} className="banner-one__person" alt="" /> */}
              <div className='row no-gutters'>
                <div className='col-xl-12'>
                  <h3 className='banner-one__title banner-one__light-color'>
                    Luth <br /> Schools
                  </h3>
                  <p className='banner-one__tag-line'>
                    Training leaders in the health sector
                  </p>
                  {/* <a href="#none" className="thm-btn banner-one__btn">
                    Learn More
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div className='banner-one__slide banner-one__slide-one custom-slide4'>
            <div className='container'>
              <div className='row no-gutters'>
                <div className='col-xl-12'>
                  <h3 className='banner-one__title banner-one__light-color'></h3>
                  <p className='banner-one__tag-line'></p>
                </div>
              </div>
            </div>
          </div>
          <div className='banner-one__slide banner-one__slide-one custom-slide'>
            <div className='container'>
              <div className='row no-gutters'>
                <div className='col-xl-12'>
                  <h3 className='banner-one__title banner-one__light-color'></h3>
                  <p className='banner-one__tag-line'></p>
                </div>
              </div>
            </div>
          </div>
          <div className='banner-one__slide banner-one__slide-one custom-slide2'>
            <div className='container'>
              <div className='row no-gutters'>
                <div className='col-xl-12'>
                  <h3 className='banner-one__title banner-one__light-color'></h3>
                  <p className='banner-one__tag-line'></p>
                </div>
              </div>
            </div>
          </div>
          <div className='banner-one__slide banner-one__slide-one custom-slide5'>
            <div className='container'>
              <div className='row no-gutters'>
                <div className='col-xl-12'>
                  <h3 className='banner-one__title banner-one__light-color'></h3>
                  <p className='banner-one__tag-line'></p>
                </div>
              </div>
            </div>
          </div>
          <div className='banner-one__slide banner-one__slide-one custom-slide3'>
            <div className='container'>
              <div className='row no-gutters'>
                <div className='col-xl-12'>
                  <h3 className='banner-one__title banner-one__light-color'></h3>
                  <p className='banner-one__tag-line'></p>
                </div>
              </div>
            </div>
          </div>

        </Swiper>
      </section>
      <div className='banner-carousel-btn'>
        <div
          onClick={goPrev}
          onKeyUp={goPrev}
          tabIndex={0}
          role='button'
          className='banner-carousel-btn__left-btn banner-arrow'
        >
          <i className='kipso-icon-left-arrow'></i>
        </div>
        <div
          onClick={goNext}
          onKeyDown={goNext}
          role='button'
          tabIndex={0}
          className='banner-carousel-btn__right-btn banner-arrow'
        >
          <i className='kipso-icon-right-arrow'></i>
        </div>
      </div>
      {/* <div className="banner-one__cta">
        <div className="banner-one__cta-icon">
          <i className="kipso-icon-black-graduation-cap-tool-of-university-student-for-head"></i>
        </div>
        <div className="banner-one__cta-title">
          <h3 className="banner-one__cta-text">
            <a href="#none">Read how we encourage our students to learn</a>
          </h3>
        </div>
        <div className="banner-one__cta-link">
          <a href="#none">
            <i className="kipso-icon-right-arrow"></i>
          </a>
        </div>
      </div> */}
    </div>
  );
};
export default SliderOne;
