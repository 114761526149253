import { Link } from 'gatsby';
import React, { Component } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import about1 from '../assets/images/about-1-1.jpg';
import statue from '../assets/images/slider/statue4.jpg';

export default class AboutTwo extends Component {
  constructor() {
    super();
    this.state = {
      startCounter: false,
    };
  }

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ startCounter: true });
    }
  };

  render() {
    return (
      <section className='about-two'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6'>
              <div className='about-two__content'>
                <div className='block-title text-left'>
                  <h2 className='block-title__title'>Welcome to LUTH</h2>
                </div>
                <p className='about-two__text'>
                  The Lagos University Teaching Hospital is a foremost tertiary
                  hospital in Nigeria , with over 950 admission beds, Forty-Six
                  (46) Clinical Departments, Eighteen (18) Non-Clinical
                  Departments and Outreaches at both Yaba and Pakoto. Presently
                  the Hospital has over Two Thousand Three hundred (2,300) Staff
                comprising of Consultants, Resident/Medical Officers, Nurses,
                  Pharmacists, Physiotherapists, Medical Laboratory scientists,
                  Nutritionists, Hospital Administrators, Engineers and other
                  categories of staff
                </p>
                {/* <div className='about-two__single-wrap'>
                  <div className='about-two__single'>
                    <div className='about-two__single-icon'>
                      <i className='kipso-icon-professor'></i>
                    </div>
                    <div className='about-two__single-content'>
                      <p className='about-two__single-text'>
                        Start learning from our experts
                      </p>
                    </div>
                  </div>
                  <div className='about-two__single'>
                    <div className='about-two__single-icon'>
                      <i className='kipso-icon-knowledge'></i>
                    </div>
                    <div className='about-two__single-content'>
                      <p className='about-two__single-text'>
                        Enhance your skills with us now
                      </p>
                    </div>
                  </div>
                </div> */}
                <Link
                  style={{ marginTop: 50 }}
                  to='/about-us'
                  className='thm-btn'
                >
                  Learn More About LUTH
                </Link>
                {/* <a href='#none' className='thm-btn'></a> */}
              </div>
            </div>
            <div className='col-xl-6 d-flex justify-content-xl-end justify-content-sm-center'>
              <div className='about-two__image'>
                <span className='about-two__image-dots'></span>
                <img src={statue} alt='' />
                {/* <div className='about-two__count'>
                  <div className='about-two__count-text'>
                    Trusted by
                    <span className='counter'>
                      <VisibilitySensor
                        onChange={this.onVisibilityChange}
                        offset={{ top: 10 }}
                        delayedCall
                      >
                        <CountUp end={this.state.startCounter ? 4890 : 0} />
                      </VisibilitySensor>
                    </span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
