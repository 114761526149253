import React from 'react';
import Swiper from 'react-id-swiper';
import { Link } from 'gatsby';
import 'swiper/css/swiper.css';

const CourseCatOne = () => {
  const params = {
    slidesPerView: 6,
    loop: true,
    speed: 1000,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },

    // Responsive breakpoints
    breakpoints: {
      1024: {
        slidesPerView: 6,
      },
      768: {
        slidesPerView: 4,
      },
      640: {
        slidesPerView: 3,
      },
      320: {
        slidesPerView: 2,
      },
    },
  };

  return (
    <section className='thm-gray-bg course-category-one'>
      <div className='container-fluid text-center'>
        <div className='block-title text-center'>
          <h2 className='block-title__title'>
            Browse our current schools <br />
            and their programs
          </h2>
          <p className='cta-two__block-title__title'>
                  LUTH school of Nursing exams will take place tomorrow on the
                  21st of september at the 
                  <br></br>
                  University of lagos (UNILAG) Distant
                  Learning Institute (DLI) at 7:00 Am
                  <br></br>
                  Candidate are expected to come to the DLI center with their:
                  <br></br>
                  Bio-Date and RRR payment slip only no other item should be
                  brought to the exam center
                  {/* Admissions is closed for entry into LUTH Schools */}
                </p>
        </div>
        <div className='course-category-one__carousel'>
          <Swiper {...params}>
            <div className='item'>
              <div className='course-category-one__single color-6'>
                <div className='course-category-one__icon'>
                  {/* <i className='kipso-icon-health'></i> */}
                </div>
                <h3 className='course-category-one__title'>
                  <Link to='/schools/anaesthetic'>Anaesthetic Technicians</Link>
                </h3>
              </div>
            </div>
            <div className='item'>
              <div className='course-category-one__single color-5'>
                <div className='course-category-one__icon'>
                  {/* <i className='kipso-icon-health'></i> */}
                </div>
                <h3 className='course-category-one__title'>
                  <Link to='/schools/comm-health'>
                    School of Community Health
                  </Link>
                </h3>
              </div>
            </div>
            <div className='item'>
              <div className='course-category-one__single color-4'>
                <div className='course-category-one__icon'>
                  {/* <i className='kipso-icon-health'></i> */}
                </div>
                <h3 className='course-category-one__title'>
                  <Link style={{ fontSize: 15 }} to='/schools/biomed'>
                    Federal School of Biomedical Engineering
                  </Link>
                </h3>
              </div>
            </div>           
            <div className='item'>
              <div className='course-category-one__single color-3'>
                <div className='course-category-one__icon'>
                  {/* <i className='kipso-icon-health'></i> */}
                </div>
                <h3 className='course-category-one__title'>
                  <Link style={{ fontSize: 17 }} to='/schools/health-info'>
                    School of Health Information Management
                  </Link>
                </h3>
              </div>
            </div>
            <div className='item'>
              <div className='course-category-one__single color-1'>
                <div className='course-category-one__icon'>
                  {/* <i className='kipso-icon-health'></i> */}
                </div>
                <h3 className='course-category-one__title'>
                  <a href='/schools/med-psy'>School of Medical And Psychiatry Social Work</a>
                </h3>
              </div>
            </div>           
            <div className='item'>
              <div className='course-category-one__single color-4'>
                <div className='course-category-one__icon'>
                  {/* <i className='kipso-icon-health'></i> */}
                </div>
                <h3  style={{marginBottom: 22}} className='course-category-one__title'>
                  <Link to='/schools/midwifery'> School of Midwifery</Link>
                </h3>
              </div>
            </div>
            <div className='item'>
              <div className='course-category-one__single color-6'>
                <div className='course-category-one__icon'>
                  {/* <i className='kipso-icon-health'></i> */}
                </div>
                <h3 style={{marginBottom: 22}} className='course-category-one__title'>
                  <Link to='/schools/nursing'>School of Nursing</Link>
                </h3>
              </div>
            </div>
            <div className='item'>
              <div className='course-category-one__single color-5'>
                <div className='course-category-one__icon'>
                  {/* <i className='kipso-icon-health'></i> */}
                </div>
                <h3 style={{marginBottom: 22}} className='course-category-one__title'>
                  <Link to='/schools/post-basic-nursing'>School of Post Basic Nursing</Link>
                </h3>
              </div>
            </div>
          </Swiper>
        </div>

        <Link to='/schools-list' className='thm-btn'>
          View All Schools
        </Link>
      </div>
    </section>
  );
};
export default CourseCatOne;
